import store from '@/store/index.js'

// 构建音频元素
// export function CreateAudioCreator () {
//   function CreateAudio () {
//     this.Players = (() => {
//       // 创建audio音频对象
//       const audio = new Audio()
//       audio.setAttribute('preload', 'auto')

//       // 创建audio对象事件监听函数
//       audio.onplay = () => {
//         store.commit('PlayMusicStatus')
//       }
//       audio.onloadedmetadata = () => { // 开始播放音乐
//         store.commit('RefreshTotleTime', audio.duration)
//       }
//       audio.onpause = () => { // 停止播放函数
//         store.commit('PauseMusicStatus')
//       }
//       audio.ontimeupdate = () => { // 音乐播放进度实时同步更新
//         store.commit('RefreshProcess', audio.currentTime)
//       }
//       audio.onended = () => { // 音乐结束
//         store.commit('NextSong')
//       }

//       return audio
//     })()
//     // 修改音频链接
//     this.ModifyAudioLink = (val) => {
//       this.Players.setAttribute('src', val)
//     }
//     // 修改音频播放进度
//     this.ModifyProcess = (val) => {
//       this.Players.currentTime = val
//     }
//   }
//   return new CreateAudio()
// }

// 构造音乐元素
export function CreateAudioCreator () {
  class AudioCreator {
    constructor () {
      this.Players = new Audio()
      // var thisProto = Object.getPrototypeOf(this)
      // thisProto.constructor.MonitoringAudio(this.Players)
    }

    ModifyAudioLink (val) { // 修改音频链接
      this.Players.setAttribute('src', val)
    }

    ModifyProcess (date) {
      this.Players.currentTime = date
    }

    plays () {
      this.Players.play()
    }

    paused () {
      this.Players.pause()
    }

    MonitoringAudio (Players) {
      // 创建audio对象事件监听函数
      Players.onplay = () => {
        store.commit('PlayMusicStatus')
      }
      Players.onloadedmetadata = () => { // 开始播放音乐
        store.commit('RefreshTotleTime', Players.duration)
      }
      Players.onpause = () => { // 停止播放函数
        store.commit('PauseMusicStatus')
      }
      Players.ontimeupdate = () => { // 音乐播放进度实时同步更新
        store.commit('RefreshProcess', Players.currentTime)
      }
      Players.onended = () => { // 音乐结束
        store.commit('NextSong')
      }
    }
  }

  var audioCreator = new AudioCreator()
  audioCreator.MonitoringAudio(audioCreator.Players)
  return audioCreator
}
