import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Vant from 'vant'
import VueCookies from 'vue-cookies'
import 'vant/lib/index.css'
import 'amfe-flexible'
import '@/assets/fontIcon/iconfont.css'
import '@/assets/fontIcon1/iconfont.css'
import { initApp } from './assets/js/init.js'
// import { CreateAudioCreator } from './assets/musicCreator.js'
import { RequestUrl } from './utils/RequestUrl.js'
import TabbarOrController from '@/components/TabbarOrController.vue'

Vue.use(Vant)
Vue.use(VueCookies)

Vue.component('BottomNavigation', TabbarOrController)
// Vue.prototype.$music = initApp()
Vue.prototype.AudioCreator = initApp()
// Vue.config.productionTip = false
Vue.prototype.$serverUrl = RequestUrl()

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
