<template>
  <van-tabbar route id="TabbarNav">
    <van-tabbar-item
      replace
      v-for="(item, index) in tabbarInfo"
      :key="index"
      :to="item.router"
    >
      <span>{{ item.text }}</span>
      <template #icon>
        <van-icon
          class="iconfont"
          class-prefix='icon'
          :size="item.size"
          :name='item.icon'
          v-if="item.isExternal"
        />
        <van-icon :name="item.icon" :size="item.size" v-else />
      </template>
    </van-tabbar-item>
  </van-tabbar>
</template>

<script>
import { GetTabbarNav } from '@/api/Start/TabbarNav.js'
export default {
  name: 'TabbarNav',
  data () {
    return {
      tabbarInfo: []
    }
  },
  methods: {
    // 获取底部导航菜单
    GetTabbarList () {
      const CookieTabbar = JSON.parse(this.$cookies.get('TabbarInfo'))
      if (CookieTabbar !== null) {
        this.tabbarInfo = CookieTabbar
      } else {
        GetTabbarNav().then(res => {
          const JsonTabbar = JSON.stringify(res.data.datas)
          this.$cookies.set('TabbarInfo', JsonTabbar, '7d')
          this.tabbarInfo = res.data.datas
        })
      }
    }
  },
  mounted () {
    this.GetTabbarList()
  }
}
</script>
