<template>
  <div id="MusicController"
  :class="!this.$route.meta.TabbarNavShow ? 'safe-bottom' : ''">
    <van-cell-group id="MusicContrTab" inset>
      <van-cell>
        <!-- 使用 title 插槽来自定义标题 -->
        <template #title>
          <div class="MusicContrTitle" @click="OpenDetails" v-if="$store.state.CurrentMusic">
            <img :src="$store.state.CurrentMusic.cover"
            class="MusicContrImg"
            v-if="$store.state.CurrentMusic.cover"/>
            <img src="img/Thumbnail/01.jpg"
            class="MusicContrImg"
            v-else/>
            <span class="ContrTitleSpan"
            v-if="$store.state.CurrentMusic.musicName">
              {{ $store.state.CurrentMusic.musicName }}
              --
              {{ $store.state.CurrentMusic.singer }}
            </span>
          </div>
          <!-- 当默认歌曲不存在 -->
          <div class="MusicContrTitle" v-else>
            <img src="img/Thumbnail/01.jpg"
            class="MusicContrImg"/>
            <span class="ContrTitleSpan" style="color: darkgray;">
              暂无歌曲信息
            </span>
          </div>
        </template>
        <template #right-icon v-if="$store.state.CurrentMusic">
          <div class="ContrSwitch">
            <van-icon name="play-circle-o"
            size="0.7rem"
            @click="startPlay"
            v-if="!$store.state.PlayStatus" />
            <van-icon name="pause-circle-o"
            size="0.7rem"
            v-else
            @click="PausePlay" />
            <van-icon class="iconfont"
            class-prefix='icon'
            name="bofangliebiao"
            size="0.75rem"
            @click="popupClick" />
          </div>
        </template>
        <template #right-icon v-else>
          <div class="ContrSwitch">
            <van-icon name="play-circle-o"
            size="0.7rem"
            color="darkgray"
            v-if="!$store.state.PlayStatus" />
            <van-icon name="pause-circle-o"
            size="0.7rem"
            color="darkgray"
            v-else />
            <van-icon class="iconfont"
            class-prefix='icon'
            name="bofangliebiao"
            size="0.75rem"
            color="darkgray" />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
    <van-popup
      class="PlayListPopup"
      v-model="popupShow"
      round
      position="bottom"
      :style="{ height: '80%' }"
    :safe-area-inset-bottom="true">
      <play-list v-if="popupShow"></play-list>
    </van-popup>
    <div style="height: 1px;"></div>
  </div>
</template>

<script>
// import { mapState } from 'vuex'
import PlayList from '@/components/PlayList.vue'
export default {
  name: 'MusicContr',
  data () {
    return {
      popupShow: false
    }
  },
  components: {
    PlayList
  },
  methods: {
    startPlay () {
      this.AudioCreator.plays()
    },
    PausePlay () {
      this.AudioCreator.paused()
    },
    OpenDetails () {
      this.$router.push({
        path: '/MusicDetails'
      })
    },
    popupClick () {
      this.popupShow = !this.popupShow
    }
  }
  // computed: {
  //   ...mapState(['CurrentMusic'])
  // }
}
</script>

<style>
  #MusicController {
    width: 100%;
    position: relative;
    padding-top: 0.2rem;
    background-color: white;
  }
  .safe-bottom {
    padding-bottom: constant(safe-area-inset-bottom); /* 兼容 iOS < 11.2 */
    padding-bottom: env(safe-area-inset-bottom);
  }
  #MusicContrTab {
    box-shadow: 3px 3px 15px darkgray;
    margin-bottom: 0.25rem;
  }
  .MusicContrTitle {
    width: 85%;
    max-height: 0.5rem;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    display: -webkit-box;
  }
  .MusicContrImg {
    position: absolute;
    width: 0.8rem;
    height: 0.8rem;
    top: 0.2rem;
  }
  .ContrTitleSpan {
    margin-left: 1rem;
  }
  .ContrSwitch {
    width: 23%;
    display: flex;
    justify-content: space-around;
  }
  .PlayListPopup {
    width: 100%;
    overflow: hidden;
  }
</style>
