<template>
  <div safe-area-inset-bottom id="TabOrController">
    <music-contr v-if="this.$route.meta.MusicContrShow"></music-contr>
    <tabbar-nav v-if="this.$route.meta.TabbarNavShow"></tabbar-nav>
  </div>
</template>

<script>
import MusicContr from '@/components/MusicContr.vue'
import TabbarNav from './TabbarNav.vue'
export default {
  name: 'TabbarOrController',
  components: {
    MusicContr,
    TabbarNav
  }
}
</script>

<style>
  #TabOrController {
    width: 100%;
    position: fixed;
    bottom: -1px;
    z-index: 2;
    left: 0;
    background-color: white;
  }
  #TabbarNav{
    position: relative;
    box-shadow: 0 -1px 0px darkgray;
    z-index: 999;
    bottom: 0;
  }
</style>
