import store from '@/store'
// import { CreateMusic } from './DefineMusic.js'
import { CreateAudioCreator } from '../musicCreator.js'

export function initApp () {
  const AudioCreator = CreateAudioCreator()
  // 打开程序时获取播放列表
  var GetPlayList = () => {
    store.commit('GetPlayList')
    if (store.state.CurrentMusic) {
      AudioCreator.ModifyAudioLink(store.state.CurrentMusic.musicHref)
    } else {
      if (store.state.PlayList.length > 0) {
        new Promise(resolve => {
          store.commit('CutCurrentMusic', store.state.PlayList[0])
          if (store.state.CurrentMusic) {
            resolve(store.state.CurrentMusic.musicHref)
          }
        }).then(res => {
          AudioCreator.ModifyAudioLink(res)
        })
      }
    }
  }

  GetPlayList()
  return AudioCreator
}
