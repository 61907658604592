<template>
  <van-cell-group class="PlayListView">
    <van-cell id="PlayListTop">
       <template #title>
        <div class="PlayListTitleBox">
          <van-icon class-prefix="icon"
          class="iconfont"
          name="shuaxin"
          size="0.75rem"
          color="#999"></van-icon>
          <p class="PlayListTitle">播放列表</p>
        </div>
       </template>
       <template #right-icon>
        <div class="PlayListIcon">
          <van-icon name="certificate" size="0.5rem" color="#999" />
          <van-icon name="sign" size="0.5rem" color="#999" />
          <van-icon name="delete-o" size="0.5rem" color="#999" @click="RemoveAllMusic" />
        </div>
       </template>
    </van-cell>
    <van-cell-group class="PlayListElemFrame">
      <van-cell class="PlayListElem"
      v-for="(item, index) in PlayList"
      :key="index">
        <template #title>
          <div class="PlayListElemTitle" @click="PlayMusic(item)">
            <span :class="item.id === $store.state.CurrentMusic.id?'PlayMusicActive': ''">
              {{ item.musicName }}
            </span>
            -
            <span class="PlayListElemSinger">
              {{ item.singer }}
            </span>
          </div>
        </template>
        <template #right-icon>
          <div class="PlayListIcon">
            <van-icon name="cross"
            size="0.5rem"
            color="darkgray"
            @click="DelPlayElem(item)" />
          </div>
        </template>
      </van-cell>
    </van-cell-group>
  </van-cell-group>
</template>

<script>
import { Toast, Dialog } from 'vant'

export default {
  name: 'PlayList',
  data () {
    return {
      PlayList: []
    }
  },
  methods: {
    GetPalyList () {
      const list = JSON.parse(localStorage.getItem('PlayInfoList'))
      this.PlayList = list
    },
    PlayMusic (item) {
      if (item.musicHref === this.$store.state.CurrentMusic.musicHref) {
        return false
      }
      this.AudioCreator.paused()
      this.$store.commit('CutCurrentMusic', item)
      this.AudioCreator.src = this.$store.state.CurrentMusic.musicHref
      this.$store.commit('AddPlayList')
    },
    DelPlayElem (item) {
      this.$store.commit('DelPlayMusic', item)
      if (item.id === this.$store.state.CurrentMusic.id) {
        this.AudioCreator.paused()
        var indexLen = this.$store.state.PlayList.length - 1
        if (this.$store.state.activeMusic > indexLen) {
          if (indexLen !== -1) {
            this.$store.commit('UpdatePlayActiveMusic', 0)
          }
        } else {
          this.$store.commit('CutCurrentMusic', this.$store.state.PlayList[this.$store.state.activeMusic])
          this.AudioCreator.ModifyAudioLink(this.$store.state.CurrentMusic.musicHref)
          this.AudioCreator.plays()
        }
      }
      this.GetPalyList()
    },
    RemoveAllMusic () {
      Dialog.confirm({
        title: '提示',
        message: '你确定要清空播放记录吗？'
      }).then(() => {
        console.log('清空')
      }).catch(() => {
        Toast('已取消')
      })
    }
  },
  mounted () {
    this.GetPalyList()
  }
}
</script>

<style>
  .PlayListView {
    height: 100%;
    overflow-y: scroll;
    -webkit-overflow-scrolling: touch;
  }
  .PlayListView::-webkit-scrollbar {
    display: none;
  }
  #PlayListTop {
    position: fixed;
    overflow: hidden;
    margin-bottom: 0.1rem;
    z-index: 999;
    border-top: 1px solid darkgray;
    border-bottom: 1px solid darkgray;
    border-radius: 15px 15px 0px 0px;
  }
  .PlayListTitleBox {
    display: flex;
    align-items: center;
  }
  .PlayListTitle {
    font-weight: bold;
    font-size: 0.45rem;
    position: relative;
    margin: 0 0.1rem;
    color: rgb(153, 153, 153);
  }
  .PlayListIcon {
    display: flex;
    align-items: center;
  }
  .PlayListIcon > i {
    margin: 0 0.25rem;
  }
  .PlayListElemFrame {
    padding-top: 1.35rem;
  }
  .PlayListElemTitle {
    width: 80%;
    overflow: hidden;
    text-overflow: ellipsis;
    -webkit-line-clamp: 1;
    display: -webkit-box;
    -webkit-box-orient: vertical;
  }
  .PlayListElemSinger {
    font-size: 0.3rem;
    color: darkgray;
  }
  .PlayMusicActive {
    color: rgb(102, 202, 169);
  }
  [class*=van-hairline]::after {
    border: 0 solid #ebedf0;
  }
</style>
