import axios from 'axios'
import cookie from 'vue-cookies'
import { RequestUrl } from './RequestUrl.js'
import router from '../router/index.js'
import { Toast } from 'vant'

export function Request (config) {
  const BaseRequest = axios.create({
    baseURL: RequestUrl(),
    timeout: 100000
  })

  // 设置请求拦截
  BaseRequest.interceptors.request.use(config => {
    const cookieToken = cookie.get('token')
    const localStorageToken = localStorage.getItem('token')
    var token = cookieToken || localStorageToken
    if (token) {
      config.headers.Authorization = 'Bearer ' + token
    }
    return config
  }, err => {
    console.log(err)
  })

  // 设置响应拦截
  BaseRequest.interceptors.response.use(config => {
    // 未设置状态码则默认成功状态
    const code = config.data.code || 200
    // 获取错误信息
    const msg = config.data.msg || '系统未知错误，请反馈给管理员'
    if (code === 401) {
      Toast('登录超时，请重新登录')
      router.push({
        path: '/Login'
      })
      return '登录状态已过期！'
    } else if (code === 500) {
      console.log(msg)
      return new Error(msg)
    } else if (code !== 200) {
      return 'error'
    }
    return config
  }, error => {
    let { message } = error
    if (message === 'Network Error') {
      message = '后端接口连接异常'
    } else if (message.includes('timeout')) {
      message = '系统接口请求超时'
    } else if (error.message.includes('Request failed with status code 401')) {
      Toast('登录超时，请重新登录')
      router.push({
        path: '/Login'
      })
      return Promise.reject(error)
    } else if (message.includes('Request failed with status code')) {
      message = '系统接口' + message.substr(message.length - 3) + '异常'
    }
    Toast(message)
    return Promise.reject(error)
  })

  return BaseRequest(config)
}
